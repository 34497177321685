import React from "react";
import { Title } from "../../Elements/Title/Title";
import DefaultLayout from "../../Layout/DefaultLayout/DefaultLayout";
import { Link } from "react-router-dom";
import "./About.scss";

const ExternalLink = ({ url, text }) => (
  <Link to={{ pathname: url }} target="_blank">
    {text}
  </Link>
);

export default () => {
  return (
    <DefaultLayout>
      <section className="about">
        <div className="about__container">
          <Title text="About COTI-X" />

          <p>
            COTI is a fully encompassing “finance on the blockchain” ecosystem
            that is designed specifically to meet the challenges of both
            centralized finance (fees, latency, global inclusion, and risk) and
            Decentralized Finance DeFi (fess, clogging, and complexity) by
            introducing a new type of DAG-based base protocol and infrastructure
            that is scalable, fast, private, inclusive, low cost and is
            optimized for finance - we call it{" "}
            <ExternalLink
              url="https://www.youtube.com/watch?v=kSdRxqHDKe8"
              text="Trustchain"
            />
            . The ecosystem includes a{" "}
            <ExternalLink
              url="https://www.youtube.com/watch?v=kSdRxqHDKe8"
              text="DAG-based Blockchain"
            />
            , a{" "}
            <ExternalLink
              url="https://coti.io/files/COTI-technical-whitepaper.pdf"
              text="Proof of Trust Consensus Algorithm"
            />
            , a{" "}
            <ExternalLink
              url="https://medium.com/cotinetwork/coti-is-launching-multidag-a-protocol-to-issue-tokens-on-a-dag-infrastructure-5c6282e5c3d1"
              text="multiDAG"
            />
            , a{" "}
            <ExternalLink
              url="https://medium.com/cotinetwork/introducing-cotis-global-trust-system-gts-an-advanced-layer-of-trust-for-any-blockchain-7e44587b8bda"
              text="Global Trust System"
            />
            , a{" "}
            <ExternalLink
              url="https://medium.com/cotinetwork/coti-universal-payment-system-ups-8614e149ee76"
              text="Universal Payment Solution"
            />
            , a{" "}
            <ExternalLink
              url="https://medium.com/cotinetwork/announcing-the-first-release-of-the-coti-payment-gateway-4a9f3e515b86"
              text="Payment Gateway"
            />
            , as well as consumer and merchant applications (
            <ExternalLink url="http://pay.coti.io/" text="COTI Pay" />
            ).
          </p>
        </div>
      </section>
    </DefaultLayout>
  );
};
