import * as actionTypes from '../actions/types';

const initialState = {
    isDesktop: window.innerWidth >= 767,
    isLargeScreen: window.innerWidth > 1365,
    windowWidth: window.innerWidth
};

export const appReducer = (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.SET_WINDOW_SIZE: {
            return { ...state, isDesktop: window.innerWidth >= 767, isLargeScreen: window.innerWidth > 1365, windowWidth: action.width }
        }
        default:
            return state;
    }
};