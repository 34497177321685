import React from 'react'
import './Card.scss';

export const Card = ({ image, description, children }) => {
    return (
        <div className="card">
            <div className="card__container">
                {image && <div className="card__container--item">
                    <img src={image} alt="card" />
                </div>}

                {description && <div className="card__container--item">
                    <p>{description}</p>
                </div>}

                {children}
            </div>
        </div>
    )
}
