export default {
  routes: {
    home: "/",
    howItWorks: "/works",
    support: "/support",
    partners: "/partners",
    contact: "/contact",
    about: "/about",
    restricted_countries: "/restricted_countries",
    faq: "/faq",
    privacy: "/privacy",
    terms: "/terms",
    checkedout: '/checked-out'
  },
  navbarRoutes: {
    works: "works",
    support: "support",
    partners: "partners",
  },
  routesClasses: [
    "works",
    "support",
    "partners",
    "about",
    "restricted_countries",
    "home",
    "faq",
    "privacy",
    "terms",
    "contact",
    "checked-out"
  ],
  navbarLinksText: {
    home: "Home",
    howItWorks: "How it works",
    support: "Support",
    partners: "Partners",
    faq: "FAQ",
    privacy: "Privacy Policy",
    terms: "Terms & Conditions",
    contact: "Contact us",
    restricted_countries: "Restricted Countries",
    checkedout: "Welcome back"
  },
  faqQuestions: [
    {
      question: "What COTI-X is?",
      answer:
        "COTI provides a new widget, enabling the purchase of COTI and  Partner-Coins with credit cards. Users can have their COTI Coins automatically swapped into Partner-Coins.",
    },
    {
      question: "How do I use COTI-X?",
      answer:
        "Using the COTI-X widget is easy. All you have to do is to select the coin you wish to purchase, insert your wallet address and pay. Your new COTI coins will appear in your wallet!",
    },
    {
      question: "Why did my purchase request fail?",
      answer:
        "We do our best to process your purchase request. However, failures to complete your purchase request may occur. Sometimes failing to submit the necessary proof of identification will result in a purchase failure, make sure that your documents comply with our requirements. Trying an alternative Credit Card might also help if the problem persists.",
    },
    {
      question: "Which countries are supported by COTI-X?",
      customAnswer: (component) => component,
      id: "restricted_countries",
    },
  ],
  restrictedCountries: [
    { name: "Albania", flag: "al.svg" },
    { name: "Bahamas", flag: "bs.svg" },
    { name: "Barbados", flag: "bb.svg" },
    { name: "Botswana", flag: "bw.svg" },
    { name: "Cambodia", flag: "kh.svg" },
    { name: "Cuba", flag: "cu.svg" },
    {
      name: "Democratic People's Republic of Korea (North Korea)",
      flag: "kp.svg",
    },
    { name: "Estonia", flag: "ee.svg" },
    { name: "Ghana", flag: "gh.svg" },
    { name: "Gibraltar", flag: "gi.svg" },
    { name: "Iceland", flag: "is.svg" },
    { name: "Iran", flag: "ir.svg" },
    { name: "Iraq", flag: "iq.svg" },
    { name: "Israel", flag: "il.svg" },
    { name: "Jamaica", flag: "jm.svg" },
    { name: "Kyrgyzstan", flag: "kg.svg" },
    { name: "Lebanon", flag: "lb.svg" },
    { name: "Mauritius", flag: "mu.svg" },
    { name: "Mongolia", flag: "mn.svg" },
    { name: "Myanmar", flag: "mm.svg" },
    { name: "Nicaragua", flag: "ni.svg" },
    { name: "Pakistan", flag: "pk.svg" },
    { name: "Panama", flag: "pa.svg" },
    { name: "Sudan", flag: "sd.svg" },
    { name: "Syria", flag: "sy.svg" },
    { name: "Trinidad and Tobago", flag: "tt.svg" },
    { name: "United States", flag: "us.svg" },
    { name: "Uganda", flag: "ug.svg" },
    { name: "Yemen", flag: "ye.svg" },
    { name: "Zimbabwe", flag: "zw.svg" },
  ],
};
