import React from "react";
import { Title } from "../../Elements/Title/Title";
import DefaultLayout from "../../Layout/DefaultLayout/DefaultLayout";
import "./Contact.scss";

export default () => {
  return (
    <DefaultLayout>
      <section className="contact">
        <div className="contact__container">
          <Title text="Contact us" />

          <p>
            Our team is here to help and answer any question you might have.
            For inquiries and suggestions, please contact:
            {" "}<a href="mailto:support@cotix.io">support@cotix.io</a> 
          </p>
        </div>
      </section>
    </DefaultLayout>
  );
};