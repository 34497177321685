import React from 'react';
import { Title } from '../../Elements/Title/Title';
import DefaultLayout from '../../Layout/DefaultLayout/DefaultLayout';
import PdfComponent from '../../Elements/LegalDoc/PdfComponent';
import files from '../../Elements/LegalDoc/files.json';
import './TermsAndConditions.scss';

export default () => {
    return (
        <DefaultLayout>
            <section className="terms">
                <div className="terms__container">
                    <Title text="Terms & Conditions" />
                    <PdfComponent fileSrc={files.terms}></PdfComponent>
                </div>
            </section>
        </DefaultLayout>
    )
}
