import { all, throttle } from 'redux-saga/effects';
import * as actionTypes from "../actions/types";

import {
    windowResize,
} from './app';

export function* watchApp() {
    yield all([
        throttle(500, actionTypes.SET_WINDOW_SIZE, windowResize),
    ])
}