import React from "react";
import { Title } from "../../Elements/Title/Title";
import DefaultLayout from "../../Layout/DefaultLayout/DefaultLayout";
import { Link } from "react-router-dom";
import "./CheckedOut.scss";

export default () => {
  return (
    <DefaultLayout>
      <section className="checked-out">
        <div className="checked-out__container">
          <Title text="Welcome back" />

          <p>
            Your COTI purchase is in process.
            A purchase notification will be sent to you via email.<br/>
            Need further assistance?, please visit our <Link to="/contact">Contact us</Link> page
          </p>
        </div>
      </section>
    </DefaultLayout>
  );
};