export const removeSlashFromPath = (path) => {
    if(path.length > 1 && path.includes('/')){
        return path.replace('/', '');
    }
    return path;
}

export const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}