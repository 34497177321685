import React, { Suspense, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom';
import config from '../../config';
import { getQueryVariable } from '../../utilities/utils';

import { Title } from '../Elements/Title/Title';
import Home from './Home/Home';
import About from './About/About';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import Faq from './Faq/Faq';
import Contact from './Contact/Contact';
import CheckedOut from './CheckedOut/CheckedOut';
import RestricedCountries from './RestricedCountries/RestricedCountries';
import DefaultLayout from '../Layout/DefaultLayout/DefaultLayout';

export const Routes = () => {
    const history = useHistory();

    const validateQueryString = (hash) => {
        const crypto = getQueryVariable('crypto');
        if (crypto === "coti" || crypto === "COTI") return;
        history.push({ search: "?crypto=COTI", hash: hash ?? "" });
    }

    history.listen(({ pathname, hash }) => {
        if (pathname === "/") {
            validateQueryString(hash);
        }
        hash !== "#how_it_works" && window.scrollTo(0, 0);
    });

    useEffect(() => {
        if (history.location.pathname === "/") {
            validateQueryString(history.location.hash);
        }
    },// eslint-disable-next-line
        []);

    return (
        <Suspense fallback={<div />}>
            <Switch>
                <Route exact path={[config.routes.home, '/#how_it_works']} component={Home} />
                <Route exact path={config.routes.about} component={About} />
                <Route exact path={config.routes.privacy} component={PrivacyPolicy} />
                <Route exact path={config.routes.terms} component={TermsAndConditions} />
                <Route exact path={config.routes.faq} component={Faq} />
                <Route exact path={config.routes.contact} component={Contact} />
                <Route exact path={config.routes.checkedout} component={CheckedOut} />
                <Route exact path={config.routes.restricted_countries} component={RestricedCountries} />

                <Route>
                    <DefaultLayout>
                        <section>
                            <Title text="404 - Page not found" />
                        </section>
                    </DefaultLayout>
                </Route>
            </Switch>
        </Suspense>
    )
}
