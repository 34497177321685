import React from 'react'
import DefaultLayout from '../../Layout/DefaultLayout/DefaultLayout';
import Simplex from '../../Simplex';
import {Works} from '../../Elements/Works/Works';

import './Home.scss';

export default () => {
    return (
        <DefaultLayout>
            <div className="home">
                <header className="home__header">
                    <h1 className="home__header--title"><span>Buy COTI or GOVI</span> Using a Credit card</h1>
                    <h2 className="home__header--subtitle">Change to your favorite token</h2>
                </header>

                <div className="home__widget">
                    <Simplex />
                </div>

                <div className="home__works">
                    <Works/>
                </div>
            </div>
    </DefaultLayout>
    )
}
