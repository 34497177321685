import React from 'react'
import { Title } from '../Title/Title';
import { Card } from '../Card/Card';
import './Works.scss';

export const Works = () => {
    return (
        <section className="works" id="how_it_works">
            <Title text="How it works" color="#fea716"/>

            <div className="works__cards">
                <Card image={require('../../../images/chosing-illustration.svg')} description="Choose the crypto pair you’d like to exchange, and the amount to purchase." />
                <Card image={require('../../../images/confirming-illustration.svg')} description="Confirm the transaction." />
                <Card image={require('../../../images/redirecting-illustration.svg')} description="Fill your details and credit card information." />
            </div>
        </section>
    )
}
