import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import { Navbar } from "./components/Elements/Navbar/Navbar";
import { Footer } from "./components/Elements/Footer/Footer";
import { useDispatch } from "react-redux";
import { setWindowSize } from "./store/actions/app";
import { Routes } from "./components/pages/Routes";

const App = () => {
  const dispatch = useDispatch();
  /* updating isDesktop prop in reducer 'app' state */
  useEffect(() => {
    window.addEventListener("resize", () =>
      dispatch(setWindowSize(window.innerWidth))
    );
    return () =>
      window.addEventListener("resize", () =>
        dispatch(setWindowSize(window.innerWidth))
      );
  }, [dispatch]);

  return (
    <Router>
      <Navbar />
      <Routes />
      <Footer />
    </Router>
  );
};

export default App;
