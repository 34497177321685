import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import config from "../../../config";
import { useScrollHandler } from "../../hooks/index";
import "./Navbar.scss";
import { HashLink } from 'react-router-hash-link';

const NavBarLinks = ({ isActive, toggleCheckbox }) => (

  <>
    <Link className={isActive(config.routes.home)} to={config.routes.home} onClick={toggleCheckbox}>
      {config.navbarLinksText.home}
    </Link>
    <HashLink to="/#how_it_works" onClick={toggleCheckbox} >{config.navbarLinksText.howItWorks}</HashLink>
    {/* <Link className={isActive(config.routes.support)} to={config.routes.support}>{config.navbarLinksText.support}</Link> */}
    {/* <Link className={isActive(config.routes.partners)} to={config.routes.partners}>{config.navbarLinksText.partners}</Link> */}
  </>
);

const CotiXLogo = (props) => {
  return (
    <Link {...props} to={config.routes.home}>
      <img
        src={require("../../../images/logos/logo-dark.svg")}
        alt="cotix logo"
      />
    </Link>
  );
};

export const Navbar = () => {
  const scrollPosition = useScrollHandler();
  const { pathname } = useLocation();
  const { isDesktop } = useSelector(({ app }) => app);
  const [toggleMenu, onToggleMenu] = useState(false);
  const isActive = (path) => (path === pathname ? "active" : "");

  return (
    <div className={`navbar${!scrollPosition ? " active" : ""}`}>
      <div className="navbar__container">
        <div className="navbar__container--logo">
          <CotiXLogo />
        </div>

        <div className={`navbar__container--links`}>
          <NavBarLinks isActive={isActive} path={pathname} />
        </div>

        <div className="navbar__container--icons">
          <Link to={config.routes.contact}>
            <img
              src={require("../../../images/icons/contact_icon.svg")}
              alt="contact logo"
            />
          </Link>
        </div>

        {!isDesktop && (
          <div className="navbar__container--menu">
            <input
              id="menu"
              type="checkbox"
              className="navbar__container--menu-checkbox"
              checked={toggleMenu}
              onChange={() => onToggleMenu(!toggleMenu)}
            />
            <div className="menu_bar_mobile">
              <CotiXLogo className="menu_bar_mobile__logo" />
              <NavBarLinks isActive={isActive} path={pathname} toggleCheckbox={() => onToggleMenu(!toggleMenu)}/>
            </div>
            <label htmlFor="menu">
              <span></span>
            </label>
          </div>
        )}
      </div>
    </div>
  );
};
