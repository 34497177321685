import React from 'react'
import { Title } from '../../Elements/Title/Title'
import DefaultLayout from '../../Layout/DefaultLayout/DefaultLayout'
import { CountriesList } from './../../Elements/Countries/CountriesList';
import './RestricedCountries.scss';

export default () => {
    return (
        <DefaultLayout>
            <section className="restricted_countries">
                <Title text="Restricted countries" />
                <CountriesList/>
            </section>
        </DefaultLayout>
    )
}
