import React from 'react';
import './Title.scss';

export const Title = ({text, color = "#6885bf"}) => {
    return (
        <h1 className="title" style={{color}}>
            {text}
        </h1>
    )
}
