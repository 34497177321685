import React from 'react';
import styled from "styled-components";
import PDF from 'react-pdf-js-infinite';
import { withRouter } from 'react-router';

const DocumentContainer = styled.div`
    max-width: 1366px;
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    > div {
        text-align: center;
        > canvas {
            margin-bottom: -115px;
        }
    }
`;


export const PdfComponent = ({ fileSrc }) => {
  return (
    <DocumentContainer>
      <PDF file={fileSrc} scale={1.5} />
    </DocumentContainer>
  );
}


export default withRouter(PdfComponent);
