import React from 'react';
import config from '../../../config'
import { Card } from '../Card/Card';
import './CountriesList.scss';

export const CountriesList = () => {
    const Country = (flag, name, index) => (<div key={index} className="countries_list__country">
        <div className="countries_list__country-flag">
            <img src={require(`../../../images/countries/${flag}`)} alt="" />
        </div>

        <div className="countries_list__country-name">
            {name}
    </div>
    </div>);

    const CountryList = () => config.restrictedCountries.map((item, index) => Country(item.flag, item.name, index))

    return (
        <Card>
            <div className="countries_list">
                {CountryList()}
            </div>
        </Card>
    )
}
