import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import config from '../../../config';
import './DefaultLayout.scss';

export default ({ children }, props) => {
    const { pathname: path } = useLocation();

    useEffect(() => {
        if (path?.includes('/') && !path?.includes("#")) {
            document.body.classList.remove(...config.routesClasses);
            if(path === '/') {
                document.body.classList.add('home');
            } else {
                path?.length > 1 && document.body.classList.add(path?.replace('/', ''));
            }
        }
    }, [path])


    return (
        <div className="layout">
            {children}
        </div>
    )
}
