import React from 'react';
import { Title } from '../../Elements/Title/Title';
import DefaultLayout from '../../Layout/DefaultLayout/DefaultLayout';
import PdfComponent from '../../Elements/LegalDoc/PdfComponent';
import files from '../../Elements/LegalDoc/files.json';
import './PrivacyPolicy.scss';

export default () => {
    return (
        <DefaultLayout>
            <section className="privacy">
                <div className="privacy__container">
                    <Title text="Privacy Policy"/>
                    <PdfComponent fileSrc={files.privacy}></PdfComponent>
                </div>
            </section>
        </DefaultLayout>
    )
}