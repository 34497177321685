import React from 'react';
import { useScript } from '../hooks';
import './style.scss';

const { REACT_APP_SIMPLEX_URL, REACT_APP_SIMPLEX_TRACKING_URL } = process.env;

export default () => {
       
    /*iFrame Sandbox Snippet Example (New Window) */
    useScript(REACT_APP_SIMPLEX_URL, () => {
        window.simplex.createForm();
    });
    
    if(REACT_APP_SIMPLEX_TRACKING_URL) {
        useScript(REACT_APP_SIMPLEX_TRACKING_URL);
    }
    /* *** END *** */
    
    return (
        <form id="simplex-form" style={{width: "100%"}}>
            <h1 id="simplex-form-head">Buy COTI/GOVI</h1>
            <div id="checkout-element">

            </div>
        </form>
    )
}