import { useState, useEffect } from "react";

export const useScript = (url, cb) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    /* onload script cb */
    script.onload = cb ? cb : () => {};

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }; //eslint-disable-next-line
  }, [url]);
};

export const useScrollHandler = () => {
  const [scroll, setScroll] = useState(1);

  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY < 10;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    };

    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [scroll, setScroll]);
  return scroll;
};
