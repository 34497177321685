import React from 'react'
import config from '../../../config';
import './Footer.scss';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';
import files from '../../Elements/LegalDoc/files.json';

export const Footer = () => {
    const { pathname } = useLocation();
    const isActive = (path) => path === pathname ? 'active' : '';
    const { isDesktop } = useSelector(({ app }) => app);

    const OpenExternalPdfFile = (e, fileName) => {
        if(!isDesktop) {
            e.preventDefault();
            window.open(fileName);
        }
    }

    return (
        <footer className="footer">
            <div className="footer__links">
                <div className="footer__links--list">

                    <ul>
                        <li> <h2 className="footer__links--list-title">Company</h2></li>
                        <li>
                            <Link className={isActive(config?.routes.about)} to={config?.routes.about}>About us</Link>
                        </li>
                    </ul>
                </div>

                <div className="footer__links--list">
                    <ul>
                        <li><h2 className="footer__links--list-title">Support</h2></li>
                        <li>
                            <HashLink to="/#how_it_works">How it works</HashLink>
                        </li>

                        <li>
                            <Link className={isActive(config?.routes.faq)} to={config?.routes.faq}>{config?.navbarLinksText.faq}</Link>
                        </li>

                        <li>
                            <Link className={isActive(config?.routes.contact)} to={config?.routes.contact}>{config?.navbarLinksText.contact}</Link>
                        </li>
                    </ul>
                </div>

                <div className="footer__links--list">

                    <ul>
                        <li><h2 className="footer__links--list-title">Legal</h2></li>
                        <li>
                          <Link className={isActive(config?.routes.privacy)} to={config?.routes.privacy} onClick={(e) => OpenExternalPdfFile(e, files.privacy)}>{config?.navbarLinksText.privacy}</Link>
                        </li>

                        <li>
                            <Link className={isActive(config?.routes.terms)} to={config?.routes.terms} onClick={(e) => OpenExternalPdfFile(e, files.terms)}>{config?.navbarLinksText.terms}</Link>
                        </li>
                    </ul>
                </div>

                <div className="footer__links--icons">
                    <img src={require('../../../images/icons/mastercard.svg')} alt="mastercard icon" />
                    <img src={require('../../../images/icons/visa.svg')} alt="visa icon" />
                </div>
            </div>

            <div className="footer__end">
                <hr></hr>
                <div>© COTI-X 2020</div>
            </div>
        </footer>
    )
}
