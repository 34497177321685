import React from "react";
import { Title } from "../../Elements/Title/Title";
import DefaultLayout from "../../Layout/DefaultLayout/DefaultLayout";
import config from "../../../config";
import { Card } from "../../Elements/Card/Card";
import { Link } from "react-router-dom";
import "./Faq.scss";

const CustomAnswer = ({ id }) => {
  if (id === "restricted_countries") {
    return (
      <>
        COTI-X is available in more than 180 countries and territories.
        <br/>Unfortunately, due to regulatory compliance some countries are not
        supported by our platform. At this time,<br/> if you are from one of the
        countries on the&nbsp;
        <Link to={config.routes.restricted_countries}>
         list of restricted countries and territories,
        </Link>
        <br/>we are unable to provide you with our services.
      </>
    );
  }
  return null;
};

export default () => {
  const renderQuestionList = () => {
    return (
      <div className="questions">
        <ul className="questions__list">
          {config?.faqQuestions.map((item, index) => (
              <li key={index} className="questions__list--item">
                <input
                  type="checkbox"
                  id={`checkbox-${index}`}
                  className="faq-dropdown"
                />
                <div className="questions__list--item-dropdown">
                  <label htmlFor={`checkbox-${index}`}>
                    <span> {item?.question}</span>
                    <div></div>
                  </label>
                </div>

                <div className="questions__list--item-answer">
                  {item?.answer ? item.answer : <CustomAnswer id={item?.id} />}
                </div>

                <hr style={{ border: "solid 1px rgba(0, 87, 255, 0.03)" }} />
              </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <DefaultLayout>
      <section className="faq">
        <div className="faq__container">
          <Title text="Frequently asked questions" />

          <Card>{renderQuestionList()}</Card>
        </div>
      </section>
    </DefaultLayout>
  );
};
